import React from 'react';
import { Card } from 'react-bootstrap';
const FundItems=({items})=>{
    return(
        <React.Fragment>
            <div style={{display:'grid',gridTemplateColumns:'repeat(auto-fit, minmax(210px, max-content))',gridGap:'16px',width:'100%'}}>
            {items.map((item,index)=>(
						<Card key={index} style={{ width: '100%',border:'2px solid #b7a485',borderRadius:'0'}}>
							<Card.Body style={{padding:'3rem 1rem'}}>
								<Card.Text style={{color:'black',fontWeight:'500',verticalAlign:'middle'}}>
									{item}
								</Card.Text>
							</Card.Body>
						</Card>
                        ))}
                        </div>
        </React.Fragment>
    )
}
export default FundItems;