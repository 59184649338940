import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import useContentful from "../../contentful";

const Index = ({ show, whichModal, close }) => {
  const { getPrivacyPolicy } = useContentful();
  const { getTerms } = useContentful();
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [terms, setTerms] = useState("");

  useEffect(() => {
    getPrivacyPolicy().then((res) =>
      setPrivacyPolicy(
        "https:" + res.items[0].fields.privacyPolicy.fields.file.url
      )
    );
    getTerms().then((res) =>
      setTerms("https:" + res.items[0].fields.termofuse.fields.file.url)
    );
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Modal
        size="xl"
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => close()}
        scrollable
        centered
      >
        <Modal.Header closeButton>{whichModal}</Modal.Header>
        <Modal.Body>
          {whichModal === "Privacy Policy" ? (
            <iframe
              title="Whitney Capital Justice Privacy Policy"
              width="100%"
              height="600"
              frameBorder="0"
              src={privacyPolicy}
            ></iframe>
          ) : (
            <iframe
              title="Whitney Capital Justice Terms & Conditions"
              width="100%"
              height="600"
              frameBorder="0"
              src={terms}
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default Index;
