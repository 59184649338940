import React from 'react';
import { Placeholder } from 'react-bootstrap';
const  CustomPlaceholder=(props)=>{
    if(props.type==='paragraph'){
        return(
            <React.Fragment>
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}> 
            <Placeholder animation="glow">
            <Placeholder as='p' style={{ width: '500px',height:'1rem' }}/>
        </Placeholder>
        <Placeholder animation="glow">
            <Placeholder as='p' style={{ width: '500px',height:'1rem' }}/>
        </Placeholder>
        <Placeholder animation="glow">
            <Placeholder as='p' style={{ width: '400px',height:'1rem' }}/>
        </Placeholder>
        <Placeholder animation="glow">
            <Placeholder as='p' style={{ width: '250px',height:'1rem' }}/>
        </Placeholder>
        </div>
            </React.Fragment>
        )
    }
}
export default CustomPlaceholder;