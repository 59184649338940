import React from 'react';
import './about.scss';
import Header from '../header';
import HeroContact from '../hero-contact';
import ContactSection from '../content-section';
import litigation from '../../assets/litigation_guide.json';

const Index = () => {
	return (
		<React.Fragment>
			<Header />
           
			<div className="about-container">
				<section className="about-contents">
					<h3>LITIGATION FUNDING GUIDE</h3>
					<div style={{ height: '1px', background: 'black', width: '100%' }} />
					{litigation.map((item, index) => (
						<div key={index} id={'funding-guide'}>
							<h3>{item.title}</h3>
							<div id={'funding-guide-description'}>
								{item.paragraph.map((x) => (
									<React.Fragment>
										<h4> {x.subtitle}</h4>
										<p>{x.text}</p>
                                        {x.text2?<p style={{marginTop:'1rem'}}>{x.text2}</p>:''}
                                        {x.text3?<p style={{marginTop:'1rem'}}>{x.text3}</p>:''}
                                        {x.text4?<p style={{marginTop:'1rem'}}>{x.text4}</p>:''}
                                        
									</React.Fragment>
								))}
							</div>
						</div>
					))}
                    <p style={{color:'black',textAlign:'left',marginTop:'2rem'}}>[1] Each funded entity funding agreement must be signed by the applicable counsel in the case or if the funded entity is a business, it must be signed by the Chief Executive Officer acting on the authority of the funded entity.</p>
				</section>
				<HeroContact />
				<ContactSection />
			</div>
           
		</React.Fragment>
	);
};
export default Index;
