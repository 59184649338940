import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import './mobilemenu.scss'

const MobileMenu = (props) => {
	return (
		<div>
			<Offcanvas show={props.open} placement={'end'} scroll={false} onHide={props.close} style={{background:'#092b2e',color:'white'}}>
				<Offcanvas.Header closeButton>
					
				</Offcanvas.Header>
				<Offcanvas.Body style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-evenly'}}>
						<li id={'menuItem'} style={{listStyleType:'none'}}><Link to={'/wcjteam'}>TEAM</Link></li>
						<li id={'menuItem'} style={{listStyleType:'none'}}><Link to={'/wcjfunding'}>WHAT WE FUND</Link></li>
						<li id={'menuItem'} style={{listStyleType:'none'}}>	<Link to={'/wcjabout'}>ABOUT</Link></li>
						<li id={'menuItem'} style={{listStyleType:'none'}}><Link to={'/wcjcontact-us'}>CONTACT</Link></li>
					
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};
export default MobileMenu;
