import React from 'react';
import { Link } from 'react-router-dom';
import './post.scss';
import Header from '../header';
import HeroContact from '../hero-contact';
import ContactSection from '../content-section';
import { Button } from 'react-bootstrap';
const Index = () => {
	return (
		<React.Fragment>
			<div className="post-container">
				<Header />
					<div className="post-contents">
						<h3>Post Settlement Fee Purchase </h3>
						<div style={{ height: '1px', background: 'black', width: '100%', marginBottom: '2rem' }} />
						<div id="settlement">
							<h3>Plaintiffs Opioid Counsel</h3>
							<div id="settlement-description">
                                <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
								<p>
									Whitney Capital Justice LLC is willing to immediately purchase a portion of your
									opioid settlement if receipt of payment is over time upon approval and disclosure of your fee and under the terms of your agreement with WCJ. For more information,
									we are available at <Link className='linktocontact' to="/wcjcontact-us">whitneycapitaljustice.com/wcjcontact-us.</Link>
								</p>
								<Button href={'/wcjcontact-us'} style={{width:'8rem',border:'none',background:'#b7a485',boxShadow:'none',borderRadius:'0'}}>
									Contact
								</Button>
                                </div>
							</div>
						</div>
					</div>
					<HeroContact />
					<ContactSection />
			</div>
		</React.Fragment>
	);
};
export default Index;
