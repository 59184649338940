import { createClient } from "contentful";
const useContentful=()=>{
    const client= createClient({
        space:process.env.REACT_APP_SPACE_ID,
        accessToken:process.env.REACT_APP_CONTENT_DELIVERY
    })

    const getFundingItems=async()=>{
      try{
        const entries = await client.getEntries({
            content_type:"whatWeFund",
            select:"fields"
        })
        return entries;
      }catch(error){
          console.log(`Error fetching funding_items : `,error)
      }
    }

    const getLitigationDescription=async()=>{
        try{
            const entries = await client.getEntries({
                content_type:"whoUsesLitigationFundingText",
                select:"fields"
            })
            return entries;
          }catch(error){
              console.log(`Error fetching litigation_text : `,error)
          }
    
    }
    const getLogo=async()=>{
        try{
            const logo=await client.getEntries({
                content_type:'whitenyCapitalLogo',
                select:"fields"
            })
            return logo

        }catch(error){
            console.log('Error fetching logo',error)
        }
    }

    const getTeam=async()=>{
        try{
            const team=await client.getEntries({
                content_type:"wcjTeamList",
                select:"fields"
            })
            return team
        }catch(error){
            console.log('Error fetching logo',error)
        }
    }
    const getFacts=async()=>{
        try{
            const facts=await client.getEntries({
                content_type:"wcjQuickFacts",
                select:"fields"
            })
            return facts
        }catch(error){
            console.log('Error fetching facts',error)
        }
        
    }
    const getPrivacyPolicy=async()=>{
        try {
            const policy=await client.getEntries({
                content_type:"wcjPrivacyPolicy",
                select:"fields"
            })
            return policy
        } catch (error) {
            console.log('Error fetching facts',error)
        }
    }
    const getTerms=async()=>{
        try {
            const conditions=await client.getEntries({
                content_type:"wcjTermsConditions",
                select:"fields"
            })
            return conditions
        } catch (error) {
            console.log('Error fetching facts',error)
        }
    }
    const getHeroText=async()=>{
        try {
            const heroText=await client.getEntries({
                content_type:"wcjLitigationHeroText",
                select:"fields"
            })
            return heroText
            
        } catch (error) {
            console.log('Error fetching hero text',error)
        }
    }

    return {getFundingItems,getLitigationDescription,getLogo,getTeam,getFacts,getPrivacyPolicy,getTerms,getHeroText}

}
export default useContentful;