import React from 'react';
import './hero-contact.scss'

const HeroContact = ({page}) => {
	const herocontactstyle = {
		minHeight: '20vh',
		width: '100%',
		backgroundColor: '#092B2E',
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding:'1rem'
	};
	const herocontactstylelite={
		minHeight: '20vh',
		width: '100%',
		backgroundColor: 'white',
		color: 'black',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
	const fontStyle2 = {
		color: 'white',
		fontFamily: 'source-serif-pro, serif',
		fontWeight: 600,
		fontStyle: 'normal',
		letterSpacing: '2px'
	};
   if(page!=='contact-us'){
	return (
		<React.Fragment>
			<div className="hero-contact" style={herocontactstyle}>
				<h5 style={{color:'#b7a485',fontFamily:'proxima-nova, sans-serif'}}>Call or email us at:</h5>
				<h1 style={fontStyle2}>1-470-610-1137</h1>
				<h3 style={fontStyle2}>info@whitneycapitaljustice.com</h3>
			</div>
		</React.Fragment>
	)
   }else{
	   return(
		<React.Fragment>
		<div className="hero-contact" style={herocontactstylelite}>
			<h5 style={{fontFamily:'proxima-nova, sans-serif'}}>Call or email us at:</h5>
			<h1 style={{color:'black'}}>1-470-610-1137</h1>
			<h3 style={{color:'black'}}>info@whitneycapitaljustice.com</h3>
		</div>
	</React.Fragment>
	   )
   }
};
export default HeroContact;
