import React from 'react';
import { Card } from 'react-bootstrap';
import './team.scss'

const TeamMemberCard=({team})=>{
    return(
        <React.Fragment>
        <div className='team-card-container'>
            {team.map((member,index)=>(
    <Card border="light" key={index} id={'team-card'} >
    <Card.Body>
      <Card.Title style={{fontSize:'2rem',fontWeight:'bold'}}>{member.fields.name}</Card.Title>
      <Card.Subtitle style={{color:'black'}}>{member.fields.jobTitle}</Card.Subtitle>
      <div style={{height:'2px',width:'inherit',background:'black',margin:'1rem 0rem'}} id={'content-seperate'}/>
      <Card.Text style={{textAlign:'left',color:'black',lineHeight:'2rem'}}>
        {member.fields.description}
      </Card.Text>
    </Card.Body>
  </Card>
            ))}
  </div>
        </React.Fragment>
    )
}
export default TeamMemberCard;