import React,{useEffect,useState} from 'react';
import useContentful from '../../contentful';
import Header from '../header';
import CustomPlaceholder from '../placeholders/placeholder';
import './facts.scss';

const Index = () => {
    const {getFacts}=useContentful();
     const [facts,setFacts]=useState([])
    useEffect(()=>{
         getFacts().then((res)=>{
             setFacts(res.items[0].fields.text)
         })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
	return (
		<React.Fragment>
			<Header />
			<div className="facts-container">
				<div className="facts-content">
					<h3>QUICK FACTS ABOUT WHITNEY CAPITAL JUSTICE LITIGATION FUNDING SERVICES</h3>

					<h5>
						WCJ is a legal assets firm which provides litigation funding for plaintiff’s firms, defense
						firms, and for corporate plaintiff’s in certain types of litigation, this funding includes:
					</h5>
					<div id="facts-guide">
                        
                            {facts.length!==0?facts.map((item,index)=>(
                                <React.Fragment>
						<li style={{listStyleType:'none'}} key={index}>
							{item}
						</li>
                        {facts.length-1===index?'':<hr></hr>}
                        </React.Fragment>
                    
                        )):
                        <React.Fragment>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                            <CustomPlaceholder type={'paragraph'}/>
                            <CustomPlaceholder type={'paragraph'}/>
                            <CustomPlaceholder type={'paragraph'}/>
                            </div>
                        </React.Fragment>
                        
                        }
                        
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default Index;
