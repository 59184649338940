import React,{useEffect,useState} from 'react';
import useContentful from '../../contentful';
import { Link } from 'react-router-dom';
import Modal from '../Modals'
import './footer.scss';
const Index=()=>{
    const {getLogo}=useContentful();
    const [logoURL,setURL]=useState('')
    const [show,setShow]=useState(false)
    const  [text,setText]=useState('')
    useEffect(()=>{
        getLogo().then((res)=>setURL(res.items[0].fields.wcj.fields.file.url))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const togglePrivacyorTerms=(term)=>{
      setShow(true)
      setText(term)
    }
    const closeModal=()=>{
        setShow(false)
    }

    return(
        <React.Fragment>
            <div className='footer-container'>
               <img src={logoURL} id={'wcjlogo'} alt={'Whitney Capital Justice'} />
               <nav id='footer-nav'>
                   {/* <li><Link id={'footer-link'} to="/wcjfacts">Quick Facts</Link></li> */}
                   <li onClick={()=>togglePrivacyorTerms('Privacy Policy')}>Privacy Policy</li>
                   <li onClick={()=>togglePrivacyorTerms('Terms Of Use')}>Terms Of Use</li>
                  <li><Link id={'footer-link'} to="/wcjcontact-us">Contact Us</Link></li> 
               </nav>
               <h6 style={{marginBottom:'0rem'}}>Atlanta | Charleston | Palm Beach</h6>
            </div>
            <Modal show={show} whichModal={text} close={closeModal}/>
        </React.Fragment>
    )
}
export default Index;