import './App.css';
import { Route,Routes,BrowserRouter} from 'react-router-dom';
import Landing from './components/landing';
import Team from './components/Team';
import Fund from './components/WhatWeFund'
import About from './components/About'
import Contact from './components/Contact'
import Post from './components/Post Settlement';
import QuickFacts from './components/Quick Facts';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/wcjteam" element={<Team/>}/>
        <Route path="/wcjfunding" element={<Fund/>}/>
        <Route path="/wcjabout" element={<About/>}/>
        <Route path="/wcjcontact-us" element={<Contact/>}/>
        <Route path="/wcjpostsettlement" element={<Post/>}/>
        <Route path="/wcjfacts" element={<QuickFacts/>}/>
         
      </Routes>
      <Footer/>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
