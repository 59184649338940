import React from 'react'
import Header from '../header';
import HeroContact from '../hero-contact';
import ContactSection from '../content-section';
import './contact.scss'
const Index=()=>{
    return(
        <React.Fragment>
            
            <div className='contact-container'>
             <Header/>
             <HeroContact page={'contact-us'}/>
              <ContactSection/>
            </div>
            
        </React.Fragment>
    )
}
export default Index;