import React,{useEffect,useState} from 'react';
import useContentful from '../../contentful';
import { Button } from 'react-bootstrap';
import Placeholder from '../placeholders/placeholder';
import './litigation.scss'
const Litigation =()=>{
    const{getLitigationDescription}=useContentful()
    const[litigationDesc,setLitDesc]=useState([])
    useEffect(()=>{
      getLitigationDescription().then((response)=>{
          setLitDesc(response.items)
          arrangeOrder(response.items)   
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const arrangeOrder=(items)=>{
        let newOrderArray=[]
        for(let i=0;i<items.length;i++){
            if(items[i].sys.id === "55JanRpUFkMctAOrLzdWbR"){
                newOrderArray.unshift(items[i])
            }else if(items[i].sys.id==="4Oq6k44FvV0M3nuMBFDjEk"){
                    newOrderArray.push(items[i])
            }else{
                newOrderArray.push(items[i])
            }
        }
        setLitDesc(newOrderArray)
    }
    return(
        <React.Fragment>
            <div id={'lit-container'} style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width:'95vw',padding:'2rem 3rem',flexWrap:'wrap',overflowX:'hidden'}}>
         {litigationDesc.length!==0?litigationDesc.map((text,index)=>(
             <div  id={'lit-text-container'} style={{width:'25vw'}} key={index}>
             <h2 style={{borderTop:'0.5rem solid #b7a485',textAlign:'left',fontWeight:'bold',paddingTop:'1rem'}}>
                 {text.fields.litigiationTitle}
             </h2>
             <p style={{textAlign:'left',fontFamily:'source-serif-pro, serif',fontWeight:'500'}}>{text.fields.litigationDescription}</p>
             </div>
         )):
         <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:"space-evenly",padding:'1rem 0rem'}} >
           <Placeholder type={'paragraph'}/>
           <Placeholder type={'paragraph'}/>
           <Placeholder type={'paragraph'}/>
         </div>
         }
         
         </div>
         <Button variant={'light'} href={'/wcjabout'} id={'learn-more'}>Learn More</Button>
        </React.Fragment>
    )
}
export default Litigation