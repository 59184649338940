import React,{useEffect,useState} from 'react';
import { Button} from 'react-bootstrap';
import Litigation from '../cards/litigation';
import Header from '../header';
import HeroContact from '../hero-contact';
import ContactSection from '../content-section';
import useContentful from '../../contentful';

import './landing.scss';
const Landing = () => {
const {getHeroText}=useContentful();
const [heroText,setHeroText]=useState({title:'',desc:''});
	useEffect(()=>{
		getHeroText().then((res)=>setHeroText(({desc:res.items[0].fields.wcjHeroText,title:res.items[0].fields.wcjTitleText})))
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	const litigationtext = [
		{
			title: 'Plaintiffʼs Law Firms',
			descr:
				'A plaintiffʼs law firm can use litigation funding for any legitimate or lawful purpose in the prosecution of a lawsuit . These uses would include the working capital for lawyers, for research, for staff salaries, for investigation and for discovery such as depositions, for expert witness fees, for travel, for infrastructure, or for additional support staff.'
		},
		{
			title: 'Business Plaintiffs',
			descr:
				'A business could find itself involved in pursuing a claim such as an insurance coverage dispute and not possess the requisite revenue to prosecute such a case because of large law firm hourly rates or it may need to fund operations while the litigation is pending. Even if a business plaintiff has sufficient capital on hand to cover legal costs the ability to defer the uncertain cost of litigation which usually would not be contemplated in an normal operating budget, litigation funding would allow capital deployment to remain controlled.'
		},
		{
			title: 'Defense Law Firms',
			descr:
				'A defense firm could rely on litigation funding to assist in client development by bringing in new or keeping present clients who are not able to afford the regular hourly rate or by reducing or delaying full regular payment by the business client when there is a contingency fee or a fee shifting claim available.'
		}
	];
	return (
		<React.Fragment>
			<div className="landing-container">
				<Header />
				<section className="hero">
					<div id="hero-contents">
						<h4>{heroText.title}</h4>
						<h1>
							{heroText.desc}
						</h1>
						<Button href={'/wcjcontact-us'} variant={'outline-light'} id={'hero-btn'}>
							Contact
						</Button>
						<h6>Atlanta | Charleston | Palm Beach</h6>
					</div>
				</section>
				<section className="hero-2">
					<div id="hero-2-contents">
						<h4>
							Litigation funding is the practice where a third-party provides capital for a plaintiffʼs
							law firm  or a business plaintiff with contingency fee cases or a defense firm involved
							in litigation in return for a financial recovery from the funded entity.
						</h4>
						<br />
						<h6>
							This allows the funded entities to access the capital necessary for a well prepared
							prosecution or defense of a lawsuit including discovery, costs, travel, expert witness fees,
							infrastructure, operating and staff and working capital for the lawyers or as an advance on
							creditworthy hourly billing.
						</h6>
						<Button href={'/wcjabout'}variant={'light'} id={'hero-2-btn'}>Learn More</Button>
						<HeroContact />
					</div>

					<section className="litigation">
						<h1>Who uses Litigation Funding?</h1>
						<Litigation text={litigationtext} />
					</section>
					<HeroContact />
					<ContactSection/>
				</section>
			</div>
			
		</React.Fragment>
	);
};
export default Landing;
