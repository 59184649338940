import React, { useState } from 'react';
import './content-section.scss';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import { useForm } from '@formspree/react';
import Alert from 'react-bootstrap/Alert';

const Index = () => {
	const cardStyle = {
		maxWidth: '40%',
		height: 'auto',
		margin: '3rem',
		background: '#e4e4e4',
		padding: '1rem'
	};
	const cardBody = {
		display: 'flex',
		flexDirection: 'column',
		padding: '2rem',
		textAlign: 'left',
		height: 'fit-content'
	};
	const [ state, handleSubmit ] = useForm('xnqwlepl');
	const [ firstname, setFirstName ] = useState('');
	const [ lastname, setLastName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ phone, setPhoneNumber ] = useState('');
	const [ message, setMessage ] = useState('');
	const [ agree, setAgree ] = useState(false);

	return (
		<React.Fragment>
				{state.succeeded ? (
							<div className='content-section-container' style={{ textAlign: 'center',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'0rem 3rem'}}>
								<Alert id={'success-notification'} style={{width:'50%',textAlign:'center',height:'15rem',display:'flex',flexDirection:'column',justifyContent:'center'}} show={state.succeeded} variant="success">
								<Alert.Heading>Thank You For Your Submission.</Alert.Heading>
								<p>A representative from Whitney Capital Justice will reach out to you.</p>
								</Alert>
							</div>
						) : (
			<div className="content-section-container">
				<Card id={'content-section-card'} style={cardStyle}>
					<Card.Body id={'content-section-card-body'} style={cardBody}>
					
							<Form onSubmit={handleSubmit}>
								<Row className="mb-3">
									<Form.Group as={Col}>
										<Form.Label style={{ marginBottom: '0rem' }}>First Name</Form.Label>
										<Form.Control
											type="text"
											name="firstname"
											value={firstname}
											onChange={(e) => setFirstName(e.target.value)}
											required
										/>
									</Form.Group>

									<Form.Group as={Col}>
										<Form.Label style={{ marginBottom: '0rem' }}>Last Name</Form.Label>
										<Form.Control
											type="text"
											name="lastname"
											value={lastname}
											onChange={(e) => setLastName(e.target.value)}
											required
										/>
									</Form.Group>
								</Row>

								<Row style={{ marginBottom: '1rem' }}>
									<Form.Group as={Col}>
										<Form.Label style={{ marginBottom: '0rem' }}>Email</Form.Label>
										<Form.Control
											type="email"
											name="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											required
										/>
									</Form.Group>

									<Form.Group as={Col}>
										<Form.Label style={{ marginBottom: '0rem' }}>Phone</Form.Label>
										<Form.Control
											type="tel"
											name="phone"
											value={phone}
											onChange={(e) => setPhoneNumber(e.target.value)}
											required
										/>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label style={{ marginBottom: '0rem' }}>Message</Form.Label>
										<Form.Control
											as="textarea"
											style={{ height: '8rem' }}
											name="message"
											value={message}
											onChange={(e) => setMessage(e.target.value)}
											required
										/>
									</Form.Group>
								</Row>
								<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check
											type="checkbox"
											label="I agree - By submitting your details, you are granting us permission to store and process your data as according to our Privacy Policy."
											style={{ fontSize: '12px', width: '90%' }}
											onChange={() => setAgree(!agree)}
											checked={agree ? true : false}
										/>
									</Form.Group>

									<Button
										disabled={
											firstname.length !== 0 &&
											lastname.length !== 0 &&
											phone.length !== 0 &&
											email.length !== 0 &&
											message.length !== 0 &&
											agree ? (
												false
											) : (
												true
											)
										}
										variant={'light'}
										type="submit"
										className="content-section-btn"
									>
										Submit
									</Button>
								</div>
							</Form>
						
					</Card.Body>
				</Card>
			</div>
			)}
		</React.Fragment>
	);
};
export default Index;
