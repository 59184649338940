import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { Button } from 'react-bootstrap';
import useContentful from '../../contentful';
import { useMediaQuery } from 'react-responsive';
import {FiMenu} from 'react-icons/fi';
import Menu from '../header/mobilemenu';
const Header = () => {
	const { getLogo } = useContentful();
	const [ logoURL, setURL ] = useState('');
	const [toggleMenu,setToggle]=useState(false)
	useEffect(
		() => {
			getLogo().then((res) => setURL(res.items[0].fields.wcj.fields.file.url));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const closeMenu=()=>{
      setToggle(false)
	}

	const containerDesktop = {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '2rem 2rem',
		width: '100%'
	};

	const ismobilePhone=useMediaQuery({query:'(max-width:600px)'})
	const isTablet = useMediaQuery({ query: '(min-width:601px) and (max-width:922px)' });
	const pathname = window.location.pathname;
	return (
		
		<React.Fragment>
			
			{!ismobilePhone && !isTablet?
			<div className={'headerContainer'} style={containerDesktop}>

			<Link to="/">
				<img
					src={logoURL}
					id={'wcjlogo'}
					alt={'Whitney Capital Justice'}
					style={{ width: '15vw'  }}
				/>
				</Link>	
				
				<div id={'header-right'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'revert',width:'50vw' }}>
					<Button variant={'outline-light'} className="headerbtn" href='/wcjpostsettlement' >
						<span style={{ fontWeight: 'bold', letterSpacing: '2px' }}>OPIOID SETTLEMENT</span>
						<br />
						<span style={{ fontWeight: 'bold', letterSpacing: '3px' }}>ATTORNEY FEE PURCHASE</span>
					</Button>
					
						<ul className="nav-elements">
							<li style={{ borderBottom: pathname === '/wcjteam' && '2px solid #b7a485' }}>
								<Link to={'/wcjteam'}>TEAM</Link>
							</li>
							<li style={{ borderBottom: pathname === '/wcjfunding' && '2px solid #b7a485' }}>
								<Link to={'/wcjfunding'}>WHAT WE FUND</Link>
							</li>
							<li style={{ borderBottom: pathname === '/wcjabout' && '2px solid #b7a485' }}>	<Link to={'/wcjabout'}>ABOUT</Link></li>
							<li style={{ borderBottom: pathname === '/wcjcontact-us' && '2px solid #b7a485' }}><Link to={'/wcjcontact-us'}>CONTACT</Link></li>
							<li>1-470-610-1137</li>
						</ul>
				</div>
			</div>
			:
			<div className={'headerContainer'} >
				<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'flex-start',margin:'1rem 0rem'}}>
				<Link to="/">
              <img
					src={logoURL}
					id={'wcjlogo'}
					alt={'Whitney Capital Justice'}
					style={{ width: '35vw' }}
				/>
				</Link>
				<Button onClick={()=>setToggle(true)} variant={'outline-light'} id={'mobile-btn'}  style={{width:'5rem'}}><FiMenu/></Button>
				
				</div>
				<Button variant={'outline-light'} className={'headerbtn-mobile'} style={{height:'auto'}} href='/wcjpostsettlement' >
						<span style={{ fontWeight: 'bold', letterSpacing: '2px',fontSize:'10px' }}>OPIOID SETTLEMENT</span>
						<br />
						<span style={{ fontWeight: 'bold', letterSpacing: '3px',fontSize:'10px'  }}>ATTORNEY FEE PURCHASE</span>
					</Button>
				<Menu open={toggleMenu} close={closeMenu}/>

			</div>
			
			}
			
		</React.Fragment>
	);
};
export default Header;
