import React, { useEffect, useState } from 'react';
import Header from '../header';
import HeroContact from '../hero-contact';
import ContactSection from '../content-section';
import TeamMemberCard from './teamMemberCard';
import useContentful from '../../contentful';
import './team.scss';
import CustomPlaceholder from '../placeholders/placeholder';
const Index = () => {
	const { getTeam } = useContentful();
	const [ team, setTeam ] = useState([]);
	useEffect(() => {
		getTeam().then((res) => {
			setTeam(res.items);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<React.Fragment>
			<Header />
			<div className="team-container">
				<section className="team-contents">
					<h3>WCJ Team</h3>

					{team.length !== 0 ? (
						<TeamMemberCard team={team} />
					) : (
						<div className="placeholder-container">
							<CustomPlaceholder type="paragraph" />
							<CustomPlaceholder type="paragraph" />
							<CustomPlaceholder type="paragraph" />
						</div>
					)}
				</section>
				<HeroContact />
				<ContactSection />
			</div>
		</React.Fragment>
	);
};
export default Index;
