import React, { useEffect,useState } from 'react';
import Header from '../header';
import HeroContact from '../hero-contact';
import ContactSection from '../content-section';
import useContentful from '../../contentful';
import FundItems from './fund_items';
import './fund.scss';
const Index = () => {
    const{getFundingItems}=useContentful();
    const [fundingItems,setItems]=useState([]);

    useEffect(()=>{
        getFundingItems().then((res)=>{
            setItems(res.items[0].fields.fundingItems)
        })
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
	return (
		<React.Fragment>
			<Header />
			<div className="fund-container">
				<section className="fund-contents">
					<h3>WHAT WE FUND</h3>
					<div style={{ height: '1px', background: 'black', width: '100%', marginBottom: '5rem' }} />
					
                        <FundItems items={fundingItems}/>
					
				</section>
				<HeroContact />
				<ContactSection />
			</div>
		</React.Fragment>
	);
};
export default Index;
